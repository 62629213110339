import React from 'react';
import banner from "../img/resultados-interreg-sudoe.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Presentación del programa Interreg Sudoe" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-success centrado" role="alert">
					<h5>Sesión de presentación de los resultados del proyecto Interreg Sudoe - COMFOR</h5>
					<h6>Fecha y horario: día 25 de abril de 17:00 a 19:30 hs</h6>
					<h6>Lugar: Centro Cultural (Casa de los Leones), Cervera</h6>
					<hr></hr>
					<strong>Objetivos del proyecto:</strong>
					<p>El principal objetivo del proyecto es potenciar los bosques complejos (mixtos e irregulares) así como la plantación de especies variadas como una estrategia de adaptación que, gracias a la obtención de masas boscosas resilientes, de respuesta al cambio climático y a la disminución de la biodiversidad.</p>
					<p>El proyecto tiene <strong>cinco objetivos específicos:</strong></p>
					<div className='izquierda'>
						<ul>
							<li>Cuantificar y valorar social y económicamente los <strong>servicios ecosistémicos</strong> (biomasa para energía, madera de calidad, secuestro de carbono, mejora del hábitat y reducción de riesgos abióticos y bióticos) de plantaciones mixtas y bosques irregulares en el SUDOE.</li>
							<li>Creación de una <strong>red experimental conjunta de bosques y plantaciones mixtas e irregulares</strong> en el SUDOE. Desarrollo de competencias y transferencia de conocimientos.</li>
							<li>Desarrollo de una <strong>estrategia para formar a especialistas en el manejo de plantaciones</strong> mixtas y bosques irregulares en SUDOE.</li>
							<li>Fomentar la <strong>especialización inteligente</strong> en el ámbito de los servicios medioambientales (gestión de riesgos y protección de la biodiversidad) y la energía procedente de fuentes renovables (biomasa) de SUDOE, a través del trabajo en red de conocimientos, la innovación y el intercambio científico. Promover el valor tecnológico de bosques complejos (mixtos e irregulares) y plantaciones mixtas como activos locales de SUDOE.</li>
							<li>Fortalecer el <strong>funcionamiento sinérgico y en red de la investigación e innovación</strong> a nivel transnacional en los sectores específicos de SUDOE basado en estrategias para lograr una especialización inteligente.</li>
					</ul>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-black bg-warning w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong>17:00 h. Café de bienvenida</strong><br></br>
						</li>
						<li><strong>17:15-17:30 h. Bienvenida institucional </strong><br></br>
						Alcalde, Director de Itagra y representantes institucionales.
						</li>
						<li><strong>17:30-18:00 h. Introducción y principales resultados del Interreg COMFOR SUDOE</strong><br></br>
						Dr. Andrés Bravo-Oviedo, coordinador del COMFOR SUDOE, científico del departamento de Biogeografía y Cambio Global del Museo Nacional de Ciencias Naturales del Consejo Superior de Investigaciones Científicas (MNCN-CSIC)
						</li>
						<li><strong>18:00-18:30 h. Los significados sociales de los bosques y guía para su inclusión en la gestión forestal en bosques complejos</strong><br></br>
						Dra. Fátima Cruz-Souza, Investigadora del Instituto de Investigación en Gestión Forestal Sostenible de la Universidad de Valladolid
						</li>
						<li><strong>18:30-19:00 h. Presentación de Martelocopios y parcelas de experimentación para la gestión de bosques complejos</strong><br></br>
						D. Cristóbal Ordóñez, Investigador del Instituto de Investigación en Gestión Forestal Sostenible de la Universidad de Valladolid
						</li>
						<li><strong>19:00-19:30 h. Presentación de Herramienta de información para la gestión forestal municipal: WEBGIS Bosque Modelo de Palencia.</strong><br></br>
						Dª Laura Martín Collado, Máster Gestión Forestal Basada en Ciencia de Datos (DATAFOREST)
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-white bg-secondary border-secondary w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Fecha y horario:</h5>
					<p className="card-text-left"> 25 de abril de 17:00 a 19:30 hs</p>
					<p className="card-text-left"> Centro Cultural (Casa de los Leones), Cervera</p>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/647" width="95%" height="380px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
					<hr></hr>
					<div className='row'>
				<div className='col-md-8 offset-md-2 col-sm-12'>
					<h5>¿Quiénes son los socios del proyecto?</h5>
					<div className='izquierda'>
						<ul>
							<li>AGENCIA ESTATAL CONSEJO SUPERIOR DE INVESTIGACIONES CIENTIFICAS</li>
							<li>UNIVERSIDAD DE OVIEDO</li>
							<li>ECOACSA RESERVA DE BIODIVERSIDAD, S.L.</li>
							<li>INSTITUT EUROPEEN DE LA FORET CULTIVEE</li>
							<li>INSTITUTO SUPERIOR DE AGRONOMIA</li>
							<li>UNIVERSIDAD DE VALLADOLID</li>
							<li>AGRESTA SOCIEDAD COOPERATIVA</li>
							<li>INSTITUT NATIONAL DE RECHERCHE POUR L’AGRICULTURE, L’ALIMENTATION ET L’ENVIRONEMENT</li>
							<li>ITAGRA CENTRO TECNOLOGICO AGRARIO Y AGROALIMENTARIO</li>
						</ul>
					</div>
					<hr></hr>
					<h5>¿Cuáles son las actividades del proyecto?</h5>
					<div className='izquierda'>
						<p>El proyecto se organiza en cuatro grupos de tareas y tres tareas transversales:</p>
						<h6>Grupos de tareas:</h6>
						<ul>
							<li>GT1: Análisis, caracterización y clasificación de bosques complejos SUDOE.</li>
							<li>GT2: Valoración económica y social de los servicios ambientales de bosques complejos y plantaciones mixtas.</li>
							<li>GT3: Red experimental para la especialización inteligente de bosques complejos y plantaciones mixtas.</li>
							<li>GT4: Estrategia de especialización inteligente en conservación y manejo sostenible de bosques complejos en el SUDOE.</li>
						</ul>
						<h6>Tareas transversales</h6>
						<ul>
							<li>T1. Gestión de proyectos.</li>
							<li>T2. Comunicación y divulgación.</li>
							<li>T3. Evaluación de resultados.</li>
						</ul>
					</div>
					<hr></hr>
					<h5>¿Cuáles es la duración del proyecto proyecto?</h5>
					<p>Este proyecto comenzó el 1 de noviembre de 2020 y finaliza en mayo de 2023.</p>
					<hr></hr>
					<h5>¿Dónde puedo encontrar más información del proyecto?</h5>
					<p>Puedes consultar más información en los siguientes enlaces: <a href="https://www.comfor-sudoe.eu">https://www.comfor-sudoe.eu</a> <a href="http://revista.mncn.csic.es/nm31/56/">http://revista.mncn.csic.es/nm31/56/</a></p>
				</div>
			</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i class="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i class="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
)

export default Home